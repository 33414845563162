/* CHATBOX
=============== */
.chatbox {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 100;
}

/* CONTENT IS CLOSE */
.chatbox__support {
  flex-direction: column;
  background: #eee;
  width: 300px;
  height: 450px;
  z-index: -123456;
  transform: translate(0%, -2%);
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
}

/* CONTENT ISOPEN */
.chatbox--active {
  transform: translateY(0px);
  z-index: 123456;
  display: flex;
}

/* BUTTON */
.chatbox__button {
  text-align: right;
}

/* HEADER */
.chatbox__header {
  position: sticky;
  background: #343541;
}

/* MESSAGES */
.chatbox__messages {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex-direction: column-reverse;
}

.messages__item {
  background: orange;
  max-width: 70.6%;
  width: fit-content;
}

.messages__item--operator {
  margin-left: auto;
}

.messages__item--visitor {
  margin-right: auto;
}

/* FOOTER */
.chatbox__footer {
  position: sticky;
  bottom: 0;
}

#cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 998;

  transition: opacity 0.5s; /* Add this line */
  opacity: 0; /* Add this line */
}

.engage-chatbot {
  background-color: #343541;
}
.chatbox__footer_ {
}
